import { Box, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { times } from 'lodash'

import { TemplatePreviewThumbnail } from '~features/template-library/components/thumbnail/TemplatePreviewThumbnail'

export interface LayoutSelectorGridProps {
  isAgencyLayoutGrid: boolean
  templates: { name: string; html: string; description: string }[]
  formattingBoxesToAdd: number
  selectedIndex: number | null
  setSelectedIndex: (index: number) => void
  agencyLayoutSelected: boolean
  setAgencyLayoutSelected: (isAgencyLayoutGrid: boolean) => void
}

export const LayoutSelectorGrid = ({
  templates,
  formattingBoxesToAdd = 0,
  isAgencyLayoutGrid,
  selectedIndex,
  setSelectedIndex,
  agencyLayoutSelected,
  setAgencyLayoutSelected,
}: LayoutSelectorGridProps): JSX.Element => {
  return (
    <SimpleGrid
      minChildWidth={'180px'}
      columns={[1, 2]}
      columnGap={'32px'}
      w={'100%'}
    >
      {templates.map(({ name, html, description }, index: number) => (
        // styling below is adapted from TemplateCard
        // TODO: check if we should make TemplateCard styling
        // reusable for both components, currently not done since
        // the styling for the box outline is slightly different
        <VStack spacing={'20px'} align={'left'} key={index}>
          <Box
            borderRadius="8px"
            id="template-layout-card"
            padding={'24px'}
            border={
              agencyLayoutSelected === isAgencyLayoutGrid &&
              index === selectedIndex
                ? '2px solid'
                : '2px solid transparent' //2px transparent border instead of none.
              //If we use none, selecting the layout causes the element size to change and causes some text shift.
            }
            borderColor={
              agencyLayoutSelected === isAgencyLayoutGrid &&
              index === selectedIndex
                ? 'interaction.main.default'
                : 'none'
            }
            backgroundColor={
              agencyLayoutSelected === isAgencyLayoutGrid &&
              index === selectedIndex
                ? 'blue.100'
                : 'grey.50'
            }
            _hover={{
              cursor: 'pointer',
              backgroundColor: 'blue.100',
            }}
            onClick={() => {
              setSelectedIndex(index)
              setAgencyLayoutSelected(isAgencyLayoutGrid)
            }}
          >
            <TemplatePreviewThumbnail
              html={html}
              align={'center'}
              aspectRatio={'0.71'}
              borderRadius={'2px'}
              bg="white"
              shadow={'sm'}
            />
          </Box>
          <VStack spacing={1} align={'left'}>
            <Text
              noOfLines={2}
              textStyle={'subhead-1'}
              color={
                agencyLayoutSelected === isAgencyLayoutGrid &&
                index === selectedIndex
                  ? 'interaction.main.default'
                  : 'none'
              }
            >
              {name}
            </Text>
            <Text paddingBottom="10" textStyle={'body-1'}>
              {description}
            </Text>
          </VStack>
        </VStack>
      ))}
      {times(formattingBoxesToAdd, (index) => (
        <Box key={index} />
      ))}
    </SimpleGrid>
  )
}
