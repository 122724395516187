export type GrowthBookFeatureValue =
  | string
  | number
  | JSON
  | boolean
  | undefined
  | { [key: string]: string }
  | { [key: string]: string }[]

export enum GrowthBookFeatures {
  templateCreate = 'template-create',
  announcementBanner = 'announcement-banner',
  accessResendNotifications = 'access-resend-notifications',
  editTemplate = 'edit-template',
  cloneTemplate = 'clone-template',
  sampleTemplateId = 'sample-template-id',
  smsTemplateMap = 'sms-template-map',
  sanitizeLambda = 'sanitize-lambda',
  agencyMapping = 'agency-mapping',
  apiIntegration = 'api-integration',
  landingPageStatistics = 'landing-page-statistics',
  announcementModal = 'announcement-modal',
  enableMenuBar = 'enable-menu-bar',
  landingPageSampleUseCases = 'landing-page-sample-use-cases',
  defaultTemplates = 'default-templates',
  agencyTemplates = 'agency-templates',
  govsgSending = 'govsg-sending',
  govsgSenderId = 'govsg-senderid',
  govsgUseAgencyCreds = 'govsg-use-agency-creds',
  ptvTemplateIdList = 'ptv-template-id-list',
}

export type GrowthBookFeaturesMap = {
  [GrowthBookFeatures.templateCreate]: boolean
  [GrowthBookFeatures.editTemplate]: boolean
  [GrowthBookFeatures.cloneTemplate]: boolean
  [GrowthBookFeatures.announcementBanner]: string
  [GrowthBookFeatures.sampleTemplateId]: number
  [GrowthBookFeatures.smsTemplateMap]: { [key: string]: string }
  [GrowthBookFeatures.sanitizeLambda]: boolean
  [GrowthBookFeatures.agencyMapping]: { [key: string]: string }
  [GrowthBookFeatures.enableMenuBar]: boolean
  [GrowthBookFeatures.announcementModal]: { [key: string]: string }[]
  [GrowthBookFeatures.landingPageStatistics]: { [key: string]: string }[]
  [GrowthBookFeatures.landingPageSampleUseCases]: { [key: string]: string }[]
  [GrowthBookFeatures.defaultTemplates]: { [key: string]: string }[]
  [GrowthBookFeatures.agencyTemplates]: { [key: string]: string }[]
  [GrowthBookFeatures.govsgSending]: boolean
  [GrowthBookFeatures.govsgSenderId]: boolean
  [GrowthBookFeatures.govsgUseAgencyCreds]: boolean
  [GrowthBookFeatures.ptvTemplateIdList]: string
  [key: string]: GrowthBookFeatureValue // used to define default types
}

export type GrowthBookUserAttributes = {
  email?: string
  agencyDomain?: string
  id?: number
  loggedIn?: boolean
}
