import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Editor as TinymceEditor } from '@tinymce/tinymce-react'

import { Loading } from '~components/Loading'
import { useUploadImage } from '~features/editor/hooks/images.hooks'
import { DEFAULT_LETTER_TEMPLATE_STYLE } from '~shared/constants/templates'
import { GrowthBookFeatures } from '~shared/types/feature-flag'

import { useTinymceApiKey } from '../hooks/tinymce.hooks'

interface TemplateEditorProps {
  initialHtml?: string
  onContentChange: React.Dispatch<React.SetStateAction<string>>
  isDisabled?: boolean
}

const DEFAULT_HTML = `<h1>This is a sample header</h1> You can add {{keywords}} enclosed in {{curly}} braces`

export const TemplateEditor = ({
  initialHtml = DEFAULT_HTML,
  onContentChange,
  isDisabled = false,
}: TemplateEditorProps): JSX.Element => {
  const { tinymceApiKey, isLoadingTinymceApiKey } = useTinymceApiKey()
  const enableMenuBarInEditor = useFeatureIsOn(
    GrowthBookFeatures.enableMenuBar as string,
  )

  const uploadImage = useUploadImage()

  if (isLoadingTinymceApiKey || !tinymceApiKey) return <Loading />

  return (
    <TinymceEditor
      apiKey={tinymceApiKey}
      initialValue={initialHtml}
      init={{
        plugins: 'code table help link pagebreak lists',
        height: '100%',
        width: '100%',
        menubar: enableMenuBarInEditor,
        statusbar: false,
        resize: false,
        toolbar1:
          'undo redo | bold italic underline strikethrough|| alignleft aligncenter alignright alignjustify | outdent indent| fontselect fontsizeselect formatselect |  numlist bullist | blocks fontfamily fontsizeinput forecolor backcolor| hr link table pagebreak image',
        font_family_formats:
          'Andale Mono=andale mono,"Monaco",monospace; Arial=arial,helvetica,sans-serif; Arial Black="Arial Black",impact,sans-serif; Baskerville="Baskerville",times new roman,serif; Courier New=courier, monospace; Georgia=georgia,palatino,serif; Helvetica=helvetica,arial,sans-serif; Impact=impact,"Arial Black",sans-serif; Monaco=Monaco,andale mono,monospace; Papyrus="Papyrus",fantasy; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times,sans-serif; Trebuchet MS=trebuchet ms,geneva,sans-serif; Verdana=verdana,geneva,sans-serif; Brush Script MT=brush script mt,cursive; Lucida Calligraphy="Lucida Calligraphy",cursive;',
        pagebreak_separator:
          '<div style="page-break-after: always; height: 0; display: block;">&nbsp;</div>',
        valid_children: '+body[style]',
        // set default styling for the editor
        // TODO: we might need to set up separate styling for the certificate layout
        setup: function (editor) {
          editor.on('BeforeSetContent', function (e) {
            if (e.content.trim() === '') {
              e.content = DEFAULT_LETTER_TEMPLATE_STYLE
            }
          })
          editor.ui.registry.addButton('image', {
            icon: 'image',
            onAction: function () {
              const input = document.createElement('input')
              input.type = 'file'
              input.accept = 'image/png, image/jpeg'
              input.onchange = async (event) => {
                // input only accepts a single file by default
                const file = (event.target as HTMLInputElement).files?.[0]
                if (!file) return
                const { url } = await uploadImage(file)
                if (!url) return
                editor.insertContent(`<img src="${url}" />`)
              }
              input.click()
            },
          })
        },
      }}
      onDrop={async (e, editor) => {
        const files = e.dataTransfer?.files
        if (!files) return
        e.preventDefault()
        e.stopImmediatePropagation()
        e.stopPropagation()
        for (const file of files) {
          const { url } = await uploadImage(file)
          if (url === null) continue
          editor.insertContent(`<img src="${url}" />`)
        }
      }}
      onDblclick={(e, editor) => {
        const target = e.target as HTMLElement
        if (target.nodeName !== 'IMG') return
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/png, image/jpeg'
        input.onchange = async (event) => {
          // input only accepts a single file by default
          const file = (event.target as HTMLInputElement).files?.[0]
          if (!file) return
          const { url } = await uploadImage(file)
          if (!url) return

          const img = target as HTMLImageElement
          editor.dom.setAttrib(img, 'src', url)
          // workaround as setting dom attribute does not seem to automatically trigger onEditorChange
          onContentChange(editor.getContent())
        }
        input.click()
      }}
      disabled={isDisabled}
      onEditorChange={onContentChange}
    />
  )
}
