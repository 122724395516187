import {
  Box,
  Button,
  HStack,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { times } from 'lodash'
import { useState } from 'react'
import { BiX } from 'react-icons/bi'
import { Navigate, useNavigate } from 'react-router-dom'

import { Loading } from '~components/Loading'
import { routes } from '~constants/routes'
import { PreviewTemplate } from '~features/issue/components/previews/PreviewTemplate'
import { TemplatePreviewThumbnail } from '~features/template-library/components/thumbnail/TemplatePreviewThumbnail'
import { REQUEST_NEW_TEMPLATE } from '~shared/constants/links'
import { DEFAULT_TEMPLATES } from '~shared/constants/templates'
import {
  GrowthBookFeatures,
  GrowthBookFeaturesMap,
} from '~shared/types/feature-flag'
import { replaceWithParams } from '~shared/util/letters'

import { LayoutSelectorGrid } from './LayoutSelectorGrid'

export const SelectTemplateLayoutPage = (): JSX.Element => {
  const navigate = useNavigate()
  const [agencyLayoutSelected, setAgencyLayoutSelected] =
    useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  const agencyMapping = useFeatureValue(
    GrowthBookFeatures.agencyMapping as string,
    {},
  ) as GrowthBookFeaturesMap[GrowthBookFeatures.agencyMapping]

  const agencyTemplates = useFeatureValue(
    GrowthBookFeatures.agencyTemplates as string,
    [],
  ) as { name: string; html: string; description: string }[]

  const defaultTemplatesWithParams = DEFAULT_TEMPLATES.map((template) => ({
    ...template,
    html: agencyMapping
      ? replaceWithParams(template.html, agencyMapping, '__', '__')
      : template.html,
  })) as { name: string; html: string; description: string }[]

  /*
  There are two grids: one for agency layouts and one for generic layouts.
  When the number of layouts in each grid is different, the layout cards in the grid with less layouts may be displayed bigger
  E.g. if there is only 1 agency layout and 3 generic layouts, the agency layout card will be 3 times as tall and and 3 times as wide wide as the generic layout cards
  To get around this, we add empty boxes to the grid with less layouts to make the number of layouts in each grid the same. Thus, the cards will all be the same size.
  We add 0 boxes to one of the grids and the difference in the number of layouts to the other grid.
  */
  const formattingBoxesToAddAgency = Math.max(
    defaultTemplatesWithParams.length - agencyTemplates.length,
    0,
  )
  const formattingBoxesToAddDefault = Math.max(
    agencyTemplates.length - defaultTemplatesWithParams.length,
    0,
  )

  const isTemplateCreateOn = useFeatureIsOn(
    GrowthBookFeatures.templateCreate as string,
  )

  if (!isTemplateCreateOn)
    return <Navigate to={`${routes.admin.index}/${routes.admin.letters}`} />

  return (
    <VStack h="100%" display="flex" flexDirection="column">
      <HStack
        w={'100%'}
        py={4}
        px={10}
        display={'flex'}
        justify={'space-between'}
        position={'fixed'}
        zIndex={10}
        backgroundColor={'grey.50'}
        pointerEvents="auto"
      >
        <HStack spacing={0}>
          <Button
            onClick={() =>
              navigate(`/${routes.admin.index}/${routes.admin.templates.index}`)
            }
            variant={'unstyled'}
            border={'none'}
            id="exit-select-template"
          >
            <BiX size={'1.7rem'} />
          </Button>
          <Text
            textStyle={'h6'}
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            maxWidth="60vw"
          >
            Choose a template layout
          </Text>
        </HStack>
        <HStack spacing={4}>
          <Button
            alignSelf="start"
            isDisabled={selectedIndex === null}
            onClick={() => {
              if (selectedIndex === null) return
              const selectedLayout = agencyLayoutSelected
                ? agencyTemplates[selectedIndex]
                : defaultTemplatesWithParams[selectedIndex]
              const templateDefaultName = agencyLayoutSelected
                ? selectedLayout.name //Assume the agency's name will already be in the agency layout name
                : agencyMapping.shortName //Otherwise, add it if the agency is known. How should this behave actually?
                ? `${agencyMapping.shortName} - ${selectedLayout.name.replace(
                    '- ',
                    '',
                  )}` // replace - with empty string
                : selectedLayout.name
              navigate(
                `/${routes.admin.index}/${routes.admin.templates.index}/edit`,
                {
                  state: {
                    template: {
                      html: selectedLayout.html,
                      name: templateDefaultName,
                    },
                  },
                },
              )
            }}
          >
            Start with this
          </Button>
        </HStack>
      </HStack>
      <HStack
        w="full"
        justify="space-between"
        pt={13}
        display="flex"
        overflow="auto"
        spacing="0"
        h="full"
      >
        <VStack
          p={10}
          align="start"
          w={'40%'}
          height="100%"
          bg={'white'}
          overflow="auto"
        >
          {agencyTemplates.length > 0 && (
            <>
              <Box paddingBottom="4">
                <Text textStyle="h4">{agencyMapping.shortName} templates</Text>
              </Box>
              <LayoutSelectorGrid
                isAgencyLayoutGrid={true}
                templates={agencyTemplates}
                formattingBoxesToAdd={formattingBoxesToAddAgency}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                agencyLayoutSelected={agencyLayoutSelected}
                setAgencyLayoutSelected={setAgencyLayoutSelected}
              />
              <Box paddingTop="2" paddingBottom="4">
                <Text textStyle="h4">Generic templates</Text>
              </Box>
            </>
          )}
          {defaultTemplatesWithParams.length > 0 ? (
            <LayoutSelectorGrid
              isAgencyLayoutGrid={false}
              templates={defaultTemplatesWithParams}
              formattingBoxesToAdd={formattingBoxesToAddDefault}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              agencyLayoutSelected={agencyLayoutSelected}
              setAgencyLayoutSelected={setAgencyLayoutSelected}
            />
          ) : (
            <Loading />
          )}
          <Text textStyle="subhead-2" pt={12}>
            Template layout not supported yet?{' '}
            <Link href={REQUEST_NEW_TEMPLATE} isExternal>
              Get help to onboard your template here
            </Link>
            .
          </Text>
        </VStack>
        <VStack width="60%" height={'100%'} bg={'slate.800'} overflow={'auto'}>
          <PreviewTemplate
            templateHtml={
              selectedIndex !== null
                ? agencyLayoutSelected
                  ? agencyTemplates[selectedIndex].html
                  : defaultTemplatesWithParams[selectedIndex].html
                : ''
            }
            isPlaceholdersHighlighted
            hasMobileToggle
          />
        </VStack>
      </HStack>
    </VStack>
  )
}
